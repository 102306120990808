import { FC } from 'react';
import Carousel from 'react-material-ui-carousel';
import { Box, Typography, useTheme } from '@mui/material';
import { CarouselProps } from './Carousel.interfaces';
import { PromptWrapper } from './Carousel.styles';

export const Component: FC<CarouselProps> = ({ label, items }) => {
  const theme = useTheme();
  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="overline2" color="textSecondary" textTransform="uppercase">
        {label}
      </Typography>
      <Carousel
        interval={5000}
        duration={1000}
        animation="fade"
        IndicatorIcon={null}
        navButtonsAlwaysInvisible={true}
        sx={{ marginTop: '6px' }}
      >
        {items.map((item) => (
          <PromptWrapper data-id="prompt-wrapper">
            <Typography variant="body2" color={theme.palette.primary.main}>
              {item}
            </Typography>
          </PromptWrapper>
        ))}
      </Carousel>
    </Box>
  );
};
