import { useTheme } from '@mui/material';
import { FullPageWithHeaderAndFooterLayout, ContentCenterer } from 'nm-ui-components';
import { PageHeader } from 'components/PageHeader';
import { PageFooter } from '../../components/PageFooter';
import { WorkloadDetails } from '../../components/WorkloadDetails';
import { WorkloadMetrics } from '../../components/WorkloadMetrics';
import { CostSection } from '../../components/CostSection';

const Home = () => {
  const theme = useTheme();
  const rpsValue = 15;
  const header = (
    <ContentCenterer>
      <PageHeader />
    </ContentCenterer>
  );
  const footer = (
    <ContentCenterer>
      <PageFooter />
    </ContentCenterer>
  );
  const body = (
    <ContentCenterer>
      <WorkloadDetails rpsValue={rpsValue} />
      <WorkloadMetrics rpsValue={rpsValue} />
      <CostSection rpsValue={rpsValue} />
    </ContentCenterer>
  );
  return (
    <FullPageWithHeaderAndFooterLayout
      header={header}
      footer={footer}
      body={body}
      sx={{
        // TODO: instead of black, should pull from theme
        background: `linear-gradient(105deg, black, ${theme.palette.surface.surfaceContainerLowest})`,
        padding: '32px',
      }}
    />
  );
};

export default Home;
