import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { CostDataPanelProps } from './CostDataPanel.interfaces';
import { BottomLeftCell, Cell, PanelContainer, TopCell } from './CostDataPanel.styles';
import { Badge } from '../Badge';

export const Component: FC<CostDataPanelProps> = ({
  primaryLabel,
  primaryLabelBadge,
  primaryValue,
  secondaryLabel,
  secondaryValue,
  tertiaryLabel,
  tertiaryValue,
}) => {
  return (
    <PanelContainer container>
      <TopCell item xs={12}>
        <Box gap={'12px'} display="flex" alignItems="center" mb={1}>
          <Typography variant="overline1" color="textSecondary">
            {primaryLabel}
          </Typography>
          {primaryLabelBadge && <Badge label={primaryLabelBadge} />}
        </Box>
        <Typography variant="metric2" color="primary.main">
          {primaryValue}
        </Typography>
      </TopCell>
      <BottomLeftCell item xs={6}>
        <Typography variant="overline1" color="textSecondary" mb={1}>
          {secondaryLabel}
        </Typography>
        <Typography variant="metric2" color="primary.main">
          {secondaryValue}
        </Typography>
      </BottomLeftCell>
      <Cell item xs={6}>
        <Typography variant="overline1" color="textSecondary" mb={1}>
          {tertiaryLabel}
        </Typography>
        <Typography variant="metric2" color="primary.main">
          {tertiaryValue}
        </Typography>
      </Cell>
    </PanelContainer>
  );
};
