import { Box, Typography, useTheme } from '@mui/material';
import { Info } from 'nm-ui-components';
import { BlockHeaderProps } from './BlockHeader.interfaces';
import { CustomDivider } from './BlockHeader.styles';
import { SvgContainer } from 'utils/SvgContainer';

export const Component = ({ label, withDivider = false }: BlockHeaderProps) => {
  const theme = useTheme();
  return (
    <Box display="flex" alignItems="center" my={3}>
      <Typography variant="h5" color="surface.onSurface">
        {label}
      </Typography>
      <Box ml={2}>
        <SvgContainer color={theme.palette.surface.onSurfaceAccent}>
          <Info />
        </SvgContainer>
      </Box>
      {withDivider && <CustomDivider />}
    </Box>
  );
};
