import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { InputContainer } from '../CostSection/CostSection.styles';
import { InputProps } from './Input.interfaces';
import { StyledTextField } from './Input.styles';

export const Component: FC<InputProps> = ({ label, prefix, disabled = false, value, onChange }) => {
  return (
    <InputContainer className={disabled ? 'disabled' : ''}>
      <Typography variant="overline2" color="surface.onSurface">
        {label}
      </Typography>
      <Box display="flex" alignItems="baseline">
        {prefix && (
          <Typography variant="body1" color="surface.onSurfaceAccent" sx={{ marginRight: 1 }}>
            {prefix}
          </Typography>
        )}
        <StyledTextField
          disabled={disabled}
          defaultValue="0.00"
          variant="standard"
          type="number"
          value={value}
          onChange={onChange}
          inputProps={{
            type: 'number',
            step: '1',
            min: '0',
          }}
        />
      </Box>
    </InputContainer>
  );
};
