import React, { ChangeEvent, useEffect, useState } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { formatValue } from '../../utils/helpers';

export const useSingleServerCost = (rpsValue: number) => {
  const [selectedServer, setSelectedServer] = useState<string | null>();
  const [customValue, setCustomValue] = useState<number>(0);
  const [isCustom, setIsCustom] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);

  useEffect(() => {
    const newValue = selectedServer === '-1';
    if (isCustom !== newValue) {
      setIsCustom(newValue);
    }
  }, [isCustom, selectedServer]);

  const handleServerSelect = (event: SelectChangeEvent<unknown>) => {
    const { value: targetValue } = event.target;
    setSelectedServer(targetValue as string);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleCustomValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value: targetValue } = event.target;
    setCustomValue(Number(targetValue));
  };

  const selectedServerValue = Number(selectedServer);
  const sanitizedServerValue = isNaN(selectedServerValue) ? 0 : selectedServerValue;
  const totalServerCost = isCustom ? customValue : sanitizedServerValue;
  const costPerRequest = totalServerCost / (rpsValue * 3600);
  const costPerXRequest = costPerRequest * (checked ? 10e3 : 10e6);
  const sumAllRequestsPromptInputTokens = 275000;
  const sumAllRequestsPromptOutputTokens = 275000;
  const benchmarkTimeSeconds = 300;
  const perInputTokenCost =
    ((isNaN(totalServerCost) ? 0 : totalServerCost) /
      ((sumAllRequestsPromptInputTokens / benchmarkTimeSeconds) * 3600)) *
    (checked ? 10e3 : 10e6);
  const perOutputTokenCost =
    ((isNaN(totalServerCost) ? 0 : totalServerCost) /
      ((sumAllRequestsPromptOutputTokens / benchmarkTimeSeconds) * 3600)) *
    (checked ? 10e3 : 10e6);

  const primaryValue = formatValue(costPerXRequest);
  const secondaryValue = formatValue(perInputTokenCost);
  const tertiaryValue = formatValue(perOutputTokenCost);

  return {
    selectedServer,
    handleServerSelect,
    customValue,
    changeCustomValue: handleCustomValueChange,
    isCustom,
    primaryValue,
    secondaryValue,
    tertiaryValue,
    checked,
    handleCheckboxChange,
    totalServerCost,
  };
};
