import { FC } from 'react';
import { Box, Grid, useTheme } from '@mui/material';
import { Combined } from 'nm-ui-components';
import { MetricsContainer } from '../MetricsContainer';
import { BlockHeader } from '../BlockHeader';
import {
  dataBars,
  dummyColumnContent,
  dataLines,
  samplePrompt,
  generatedPrompt,
  detailsSectionBottomBlock,
  detailsSectionMiddleBottomBlock,
} from '../../pages/home/DummyData';
import { MeanMetricSummary } from '../MeanMetricSummary';
import { GraphTitle } from '../GraphTitle';
import { DataPanel } from '../DataPanel';
import { SpecBadge } from '../SpecBadge';
import { Carousel } from '../Carousel';
import { WorkloadDetailsProps } from './WorkloadDetails.interfaces';

export const Component: FC<WorkloadDetailsProps> = ({ rpsValue }) => {
  const renderLeftColumn = (
    <MeanMetricSummary meanValue="##" meanUnit="tokens" rpsValue={rpsValue} />
  );
  const theme = useTheme();
  return (
    <>
      <BlockHeader label="Workload Details" />
      <Grid container spacing={0} data-id="workload-details" sx={{ marginLeft: 0 }}>
        <DataPanel
          header="Prompt"
          topContainer={<Carousel items={samplePrompt} label="Sample Prompt" />}
          bottomContainer={detailsSectionBottomBlock('Mean Prompt Length')}
          key="dp-1"
        />
        <DataPanel
          header="Server"
          topContainer={
            <Box display="flex" flexDirection="column" sx={{ width: '100%' }}>
              <SpecBadge label="Target" value="www.128.0.0.1.com" variant="body2" />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <SpecBadge label="Type" value="HTTP" variant="body2" />
                </Grid>
                <Grid item xs={6}>
                  <SpecBadge label="Port" value="8000" variant="body2" />
                </Grid>
              </Grid>
            </Box>
          }
          bottomContainer={detailsSectionMiddleBottomBlock(theme, rpsValue)}
          key="dp-2"
        />
        <DataPanel
          header="Generated"
          topContainer={<Carousel items={generatedPrompt} label="Sample Generated" />}
          bottomContainer={detailsSectionBottomBlock('Mean Generated Length')}
          key="dp-3"
        />
      </Grid>
      <Grid container spacing={7} my={2} px={1}>
        <Grid item xs={6} pr={2}>
          <MetricsContainer
            header="Request Prompt Length"
            leftColumn={renderLeftColumn}
            rightColumn={dummyColumnContent}
          >
            <GraphTitle title="Request Prompt Length" />
            <Combined
              bars={dataBars}
              lines={dataLines}
              width={540}
              height={238}
              margins={{ left: 50, bottom: 50 }}
              xLegend="length (tokens)"
              yLegend="frequency"
            />
          </MetricsContainer>
        </Grid>
        <Grid item xs={6}>
          <MetricsContainer
            header="REQUEST GENERATION LENGTH"
            leftColumn={renderLeftColumn}
            rightColumn={dummyColumnContent}
          >
            <GraphTitle title="Request Generation Length" />
            <Combined
              bars={dataBars}
              lines={dataLines}
              width={540}
              height={238}
              margins={{ left: 50, bottom: 50 }}
              xLegend="length (tokens)"
              yLegend="frequency"
            />
          </MetricsContainer>
        </Grid>
      </Grid>
    </>
  );
};
