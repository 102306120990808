import { Config } from './Config';

type JsonConfig = {
  authority: string;
  baseUrl: string;
  clientId: string;
  gitSha: string;
  version: string;
};

type GlobalWithConfig = {
  config?: JsonConfig;
};

interface WindowWithConfig extends Window {
  __NM_NM_VLLM_WORKLOAD_ANALYZER_UI__?: GlobalWithConfig;
}

const DEFAULT_CONFIG: Config = new Config({
  authority: 'https://accounts.staging.neuralmagic.com/v1',
  baseUrl: 'http://0.0.0.0:3000',
  clientId: 'c0ffeec0-ffee-c0ff-eec0-ffeec0ffeec0',
  gitSha: process.env.REACT_APP_NM_VLLM_WORKLOAD_ANALYZER_UI_GIT_SHA || 'NONE',
  version: process.env.REACT_APP_NM_VLLM_WORKLOAD_ANALYZER_UI_VERSION || 'NONE',
});

const windowWithConfig = window as WindowWithConfig;

const ENV_CONFIG = windowWithConfig?.__NM_NM_VLLM_WORKLOAD_ANALYZER_UI__?.config
  ? new Config(windowWithConfig.__NM_NM_VLLM_WORKLOAD_ANALYZER_UI__.config)
  : undefined;

export const CONFIG: Config = ENV_CONFIG || DEFAULT_CONFIG;
