import { ChangeEvent, useState } from 'react';
import { formatValue } from '../../utils/helpers';

export const useDeploymentCost = (rpsValue: number, totalServerCost: number) => {
  const [inputValue, setInputValue] = useState<number>(0);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value: targetValue } = event.target;
    setInputValue(Number(targetValue));
  };

  const hoursInMonth = 24 * 30;

  const rowPrimaryValue = Math.ceil(inputValue / rpsValue);
  const primaryValue = inputValue === 0 ? '# #' : rowPrimaryValue.toString();
  const secondaryValue = formatValue(rowPrimaryValue * totalServerCost);
  const tertiaryValue = formatValue(rowPrimaryValue * totalServerCost * hoursInMonth);

  return {
    inputValue,
    changeInputValue: handleInputChange,
    primaryValue,
    secondaryValue,
    tertiaryValue,
  };
};
