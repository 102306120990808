import React, { FC } from 'react';
import { Box, Checkbox, Typography, useTheme, FormControlLabel } from '@mui/material';
import { BlockHeader } from '../BlockHeader';
import { CostDataPanel } from '../CostDataPanel';
import { Expand } from '../../assets/icons';
import { Input } from '../Input';
import {
  InputContainer,
  OptionDivider,
  OptionItem,
  StyledFormControl,
  StyledInputLabel,
  StyledSelect,
} from './CostSection.styles';
import { useSingleServerCost } from './useSingleServerCost';
import { useDeploymentCost } from './useDeploymentCost';
import { CostSectionProps } from './CostSection.interfaces';

const options = [
  { value: '4', label: 'AWS A100 (p4d.24xlarge): $4.096' },
  { value: '16', label: 'AWS 4xA100 (p4d.24xlarge): $16.386' },
  { value: '32', label: 'AWS 8xA100 (p4d.24xlarge): $32.772' },
];

export const Component: FC<CostSectionProps> = ({ rpsValue }) => {
  const theme = useTheme();
  const {
    selectedServer,
    handleServerSelect,
    isCustom,
    customValue,
    changeCustomValue,
    primaryValue: singleCostPrimaryValue,
    secondaryValue: singleCostSecondaryValue,
    tertiaryValue: singleCostTertiaryValue,
    checked,
    handleCheckboxChange,
    totalServerCost,
  } = useSingleServerCost(rpsValue);
  const {
    inputValue,
    changeInputValue,
    primaryValue: deploymentPrimaryValue,
    secondaryValue: deploymentSecondaryValue,
    tertiaryValue: deploymentTertiaryValue,
  } = useDeploymentCost(rpsValue, totalServerCost);

  return (
    <>
      <BlockHeader label="Single Server Cost" />

      <Box
        display="flex"
        flexDirection="row"
        gap={2}
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Box display="flex" flexDirection="row" gap={2}>
          <InputContainer>
            <StyledFormControl fullWidth sx={{ fieldset: { legend: { maxWidth: '100%' } } }}>
              <StyledInputLabel shrink={true}>COST/SERVER/HOUR</StyledInputLabel>
              <StyledSelect
                autoWidth
                value={selectedServer}
                placeholder="Select server or custom cost"
                onChange={handleServerSelect}
                IconComponent={Expand}
                displayEmpty
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: theme.palette.surface.surfaceContainerLow,
                      borderRadius: '8px',
                    },
                  },
                }}
              >
                <OptionItem value="-1">Custom</OptionItem>
                <OptionItem
                  value="-100500"
                  disabled={true}
                  sx={{ paddingLeft: 0, paddingRight: 0 }}
                >
                  <OptionDivider />
                </OptionItem>
                {options.map(({ value, label }) => (
                  <OptionItem value={value}>{label}</OptionItem>
                ))}
              </StyledSelect>
            </StyledFormControl>
          </InputContainer>

          <Input
            label="Custom COST/SERVER/HOUR"
            prefix="$"
            disabled={!isCustom}
            value={customValue}
            onChange={changeCustomValue}
          />
        </Box>

        <FormControlLabel
          label={
            <Typography variant="subtitle2" color="white" sx={{ userSelect: 'none' }}>
              Show prices per 1K tokens
            </Typography>
          }
          control={
            <Checkbox
              color="primary"
              checked={checked}
              onChange={handleCheckboxChange}
              sx={{ color: theme.palette.primary.main }}
            />
          }
        />
      </Box>

      <CostDataPanel
        primaryLabel={checked ? 'Cost/1K Requests at' : 'Cost/1M Requests at'}
        primaryLabelBadge={`${rpsValue} rps`}
        primaryValue={singleCostPrimaryValue}
        secondaryLabel={checked ? 'Cost/1K input tokens' : 'Cost/1M input tokens'}
        secondaryValue={singleCostSecondaryValue}
        tertiaryLabel={checked ? 'Cost/1K output tokens' : 'Cost/1M output tokens'}
        tertiaryValue={singleCostTertiaryValue}
      />

      <BlockHeader label="Deployment Cost" />

      <Input label="Expected request/sec" value={inputValue} onChange={changeInputValue} />

      <CostDataPanel
        primaryLabel="Number of Instances"
        primaryValue={deploymentPrimaryValue}
        secondaryLabel="Cost/hour"
        secondaryValue={deploymentSecondaryValue}
        tertiaryLabel="Cost/month"
        tertiaryValue={deploymentTertiaryValue}
      />
    </>
  );
};
