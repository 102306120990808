import { Box, Chip, Link, Typography, useTheme } from '@mui/material';
import { Open } from 'nm-ui-components';
import { SvgContainer } from '../../utils/SvgContainer';
import { SpecBadge } from '../SpecBadge';
import { HeaderCell, HeaderWrapper } from './PageHeader.styles';

export const Component = () => {
  const theme = useTheme();
  return (
    <Box py={2}>
      <Typography variant="subtitle2" color="surface.onSurfaceAccent">
        nm-vllm LLM Guidance
      </Typography>
      <Typography variant="h4" color="surface.onSurface" my={'12px'}>
        Workload Report: Single Experiment
      </Typography>
      <HeaderWrapper container>
        <HeaderCell item xs={3} withDivider sx={{ paddingLeft: 0 }}>
          <SpecBadge
            label="Model"
            value="llama2-7b-llama2_chat_llama2_pretrain-base_quantized"
            variant="metric2"
            withTooltip
          />
          <SpecBadge label="Model size" value="4.4 GB" variant="body1" />
        </HeaderCell>
        <HeaderCell item xs={2} withDivider>
          <SpecBadge
            label="Hardware"
            value="A10"
            variant="metric2"
            additionalValue={
              <Chip
                label="x3"
                color="primary"
                variant="filled"
                size="small"
                sx={{ padding: '0 5px' }}
              />
            }
            key="Hardware"
          />
          <SpecBadge label="Interconnect" value="PCIE" variant="body1" key="Interconnect" />
          <SpecBadge label="Version" value="9.0" variant="body1" key="Version" />
        </HeaderCell>
        <HeaderCell item xs={2} withDivider>
          <SpecBadge label="Task" value="Chat" variant="metric2" />
        </HeaderCell>
        <HeaderCell item xs={3} withDivider>
          <SpecBadge
            label="Dataset"
            value="Llama3 Chat"
            variant="metric2"
            additionalValue={
              <Link href="https://example.com" target="_blank">
                <SvgContainer color={theme.palette.primary.main}>
                  <Open />
                </SvgContainer>
              </Link>
            }
          />
        </HeaderCell>
        <HeaderCell item xs={2} sx={{ paddingRight: 0 }}>
          <SpecBadge label="Time Stamp" value="June 14, 2023 00:00:00" variant="caption" />
        </HeaderCell>
      </HeaderWrapper>
    </Box>
  );
};
