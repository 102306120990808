import { FC, Fragment } from 'react';
import { Box, Typography, Tooltip } from '@mui/material';
import { SpecBadgeProps } from './SpecBadge.interfaces';
import { Container, EllipsisTypography, ValueWrapper } from './SpecBadge.styles';

export const Component: FC<SpecBadgeProps> = ({
  label,
  value,
  variant,
  additionalValue,
  withTooltip = false,
}) => {
  const Wrapper = withTooltip ? Tooltip : Fragment;
  return (
    <Container>
      <Typography variant="overline2" color="textSecondary">
        {label}
      </Typography>
      <ValueWrapper>
        <Wrapper title={value}>
          <EllipsisTypography variant={variant} color="primary">
            {value}
          </EllipsisTypography>
        </Wrapper>
        {additionalValue && <Box ml={1}>{additionalValue}</Box>}
      </ValueWrapper>
    </Container>
  );
};
