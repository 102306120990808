import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { muiThemeV3Dark } from 'nm-ui-components';

import PackageInfo from 'pages/packageInfo';
import Home from './pages/home';

function App() {
  return (
    <ThemeProvider theme={muiThemeV3Dark}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/.nm/info" element={<PackageInfo />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
