import { Box, Grid, Theme, Typography } from '@mui/material';
import { ContainerSize, MiniCombined, ContainerSizeWrapper } from 'nm-ui-components';
import { DistributionPercentiles, PercentileItem } from '../../components/DistributionPercentiles';
import { MeanMetricSummary } from '../../components/MeanMetricSummary';
import { SpecBadge } from '../../components/SpecBadge';
import { Badge } from '../../components/Badge';

const percentileProps: PercentileItem[] = [
  { label: 'p50', value: '##ms' },
  { label: 'p90', value: '##ms' },
  { label: 'p95', value: '##ms' },
  { label: 'p99', value: '##ms' },
];

export const dummyColumnContent = <DistributionPercentiles list={percentileProps} />;

export const dummyLeftColumn = (rpsValue: number) => (
  <MeanMetricSummary meanValue="##" meanUnit="tokens" rpsValue={rpsValue} />
);

export const dummyLeftColumn3 = (rpsValue: number) => (
  <MeanMetricSummary meanValue="##" meanUnit="tokens" rpsValue={rpsValue} />
);

export const dataBars = [
  { x: 0.5, y: 1 },
  { x: 1, y: 1 },
  { x: 1.5, y: 8 },
  { x: 2, y: 12 },
  { x: 2.5, y: 14 },
  { x: 3, y: 13 },
  { x: 3.5, y: 10 },
  { x: 4, y: 6 },
  { x: 4.5, y: 4 },
  { x: 5, y: 4 },
  { x: 5.5, y: 2 },
  { x: 6, y: 1 },
  { x: 6.5, y: 1 },
  { x: 7, y: 1 },
  { x: 7.5, y: 1 },
  { x: 8, y: 5 },
  { x: 9.5, y: 4 },
  { x: 10, y: 3 },
];

export const dataLines = [
  { x: 1.5, y: 35, id: 'p50' },
  { x: 2, y: 35, id: 'p90' },
  { x: 3.5, y: 45, id: 'p95' },
];

export const dashedLinesData = [
  {
    id: 'Mean',
    data: [
      { x: 0, y: 6 },
      { x: 1, y: 5 },
      { x: 2, y: 8 },
      { x: 3, y: 9 },
      { x: 4, y: 10 },
      { x: 5, y: 11 },
      { x: 6, y: 12 },
      { x: 7, y: 13 },
      { x: 8, y: 11 },
      { x: 9, y: 10 },
    ],
    solid: true,
  },
  {
    id: 'p50',
    data: [
      { x: 0, y: 8 },
      { x: 1, y: 7 },
      { x: 2, y: 9 },
      { x: 3, y: 10 },
      { x: 4, y: 12 },
      { x: 5, y: 13 },
      { x: 6, y: 14 },
      { x: 7, y: 15 },
      { x: 8, y: 14 },
      { x: 9, y: 13 },
    ],
  },
  {
    id: 'p90',
    data: [
      { x: 0, y: 4 },
      { x: 1, y: 3.5 },
      { x: 2, y: 5 },
      { x: 3, y: 5.5 },
      { x: 4, y: 6 },
      { x: 5, y: 6.5 },
      { x: 6, y: 7 },
      { x: 7, y: 7.5 },
      { x: 8, y: 7 },
      { x: 9, y: 6.5 },
    ],
  },
  {
    id: 'p95',
    data: [
      { x: 0, y: 2.5 },
      { x: 1, y: 2 },
      { x: 2, y: 3 },
      { x: 3, y: 3.5 },
      { x: 4, y: 4 },
      { x: 5, y: 4.5 },
      { x: 6, y: 5 },
      { x: 7, y: 5.5 },
      { x: 8, y: 5 },
      { x: 9, y: 4.5 },
    ],
  },
];

export const samplePrompt = [
  'The sun sets beautifully over the horizon.',
  'She found a hidden gem in the old bookstore.',
  'He quickly solved the puzzle with ease.',
  'The coffee shop was cozy and inviting.',
  'The garden bloomed with colorful flowers.',
];

export const generatedPrompt = [
  'AI is transforming the tech industry fast.',
  'Machine learning powers smart assistants now.',
  'AI algorithms can predict user behavior.',
  'Robots driven by AI are becoming common.',
  'AI enhances image recognition accuracy greatly.',
];

const dataMiniBars = [
  { x: 0.5, y: 1 },
  { x: 1, y: 1 },
  { x: 1.5, y: 8 },
  { x: 2, y: 12 },
  { x: 2.5, y: 14 },
  { x: 3, y: 13 },
  { x: 3.5, y: 10 },
  { x: 4, y: 6 },
  { x: 4.5, y: 4 },
  { x: 5, y: 4 },
  { x: 5.5, y: 2 },
  { x: 6, y: 1 },
  { x: 6.5, y: 1 },
  { x: 7, y: 1 },
  { x: 7.5, y: 1 },
  { x: 8, y: 5 },
  { x: 9.5, y: 4 },
  { x: 10, y: 3 },
];

const dataMiniLines = [{ x: 1.5, y: 35, id: 'p50' }];

export const detailsSectionBottomBlock = (label: string) => (
  <Box
    display="flex"
    flexDirection="column"
    sx={{ width: '100%' }}
    justifyItems="center"
    alignItems="space-between"
  >
    <Box display="flex" flexDirection="column">
      <Box>
        <Typography variant="overline2" color="textSecondary">
          {label}
        </Typography>
      </Box>
      <Box mt="8px" mb="16px">
        <Typography variant="metric1" color="primary">
          ## tokens
        </Typography>
      </Box>
    </Box>
    <div style={{ width: '100%', height: '85px' }}>
      <ContainerSizeWrapper>
        {(containerSize: ContainerSize) => (
          <MiniCombined
            bars={dataMiniBars}
            lines={dataMiniLines}
            width={312}
            height={85}
            xLegend="length (tokens)"
            margins={{ bottom: 30 }}
            containerSize={containerSize}
          />
        )}
      </ContainerSizeWrapper>
    </div>
  </Box>
);

export const detailsSectionMiddleBottomBlock = (theme: Theme, rpsValue: number) => (
  <Box display="flex" flexDirection="column" sx={{ width: '100%' }} justifyItems="center">
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <SpecBadge label="Mean Prompt Length" value="## tokens" variant="body2" />
      </Grid>
      <Grid item xs={6}>
        <SpecBadge label="Number of Benchmarks" value="100" variant="body2" />
      </Grid>
      <Grid item xs={6}>
        <Typography variant="overline2" color="textSecondary" textTransform="uppercase">
          Request over Time at
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Badge label={`${rpsValue} rps`} />
      </Grid>
    </Grid>
    <div style={{ width: '100%', height: '85px' }}>
      <ContainerSizeWrapper>
        {(containerSize: ContainerSize) => (
          <MiniCombined
            bars={dataMiniBars}
            lines={dataMiniLines}
            width={312}
            height={85}
            xLegend="length (tokens)"
            margins={{ bottom: 30 }}
            containerSize={containerSize}
          />
        )}
      </ContainerSizeWrapper>
    </div>
  </Box>
);
