import { Grid, styled } from '@mui/material';

export const PanelContainer = styled(Grid)(({ theme }) => ({
  borderColor: theme.palette.outline.subdued,
  borderWidth: '1px',
  borderStyle: 'solid',
  borderRadius: '8px',
  backgroundColor: theme.palette.surface.surfaceContainerLow,
}));

export const Cell = styled(Grid)(() => ({
  padding: '24px',
  display: 'flex',
  flexDirection: 'column',
}));

export const TopCell = styled(Cell)(({ theme }) => ({
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid',
  borderBottomColor: theme.palette.outline.subdued,
}));

export const BottomLeftCell = styled(Cell)(({ theme }) => ({
  borderRightWidth: '1px',
  borderRightStyle: 'solid',
  borderRightColor: theme.palette.outline.subdued,
}));
