import { FC } from 'react';
import { Grid } from '@mui/material';
import { DashedLine } from 'nm-ui-components';
import { BlockHeader } from '../BlockHeader';
import { MetricsContainer } from '../MetricsContainer';
import { GraphTitle } from '../GraphTitle';
import {
  dummyColumnContent,
  dummyLeftColumn,
  dummyLeftColumn3,
  dashedLinesData,
} from '../../pages/home/DummyData';
import { GraphsWrapper } from './WorkloadMetrics.styles';
import { WorkloadMetricSummaryProps } from './WorkloadMetricSummary.interfaces';

export const Component: FC<WorkloadMetricSummaryProps> = ({ rpsValue }) => {
  return (
    <>
      <BlockHeader label="Metrics Details" />
      <Grid container spacing={7} pt={1} px={1}>
        <Grid item xs={6} pr={2}>
          <MetricsContainer
            header="TTFT"
            leftColumn={dummyLeftColumn(rpsValue)}
            rightColumn={dummyColumnContent}
          >
            <GraphTitle title="TTFS vs RPS" />
            <GraphsWrapper>
              <DashedLine
                data={dashedLinesData}
                margins={{ left: 50, bottom: 50 }}
                xLegend="request per sec"
                yLegend="ttft (ms)"
              />
            </GraphsWrapper>
          </MetricsContainer>
        </Grid>
        <Grid item xs={6}>
          <MetricsContainer header="TPOT" leftColumn={dummyLeftColumn3(rpsValue)}>
            <GraphTitle title="TPOT vs RPS" />
            <GraphsWrapper>
              <DashedLine
                data={dashedLinesData}
                margins={{ left: 50, bottom: 50 }}
                xLegend="request per sec"
                yLegend="tpot (ms)"
              />
            </GraphsWrapper>
          </MetricsContainer>
        </Grid>
        <Grid item xs={6} pr={2}>
          <MetricsContainer
            header="E2E Latency"
            leftColumn={dummyLeftColumn(rpsValue)}
            rightColumn={dummyColumnContent}
          >
            <GraphTitle title="E2E Latency vs RPS" />
            <GraphsWrapper>
              <DashedLine
                data={dashedLinesData}
                margins={{ left: 50, bottom: 50 }}
                xLegend="request per sec"
                yLegend="latency (ms)"
              />
            </GraphsWrapper>
          </MetricsContainer>
        </Grid>
        <Grid item xs={6}>
          <MetricsContainer header="Throughput" leftColumn={dummyLeftColumn3(rpsValue)}>
            <GraphTitle title="Throughput vs RPS" />
            <GraphsWrapper>
              <DashedLine
                data={dashedLinesData}
                margins={{ left: 50, bottom: 50 }}
                xLegend="request per sec"
                yLegend="throughput (ms)"
              />
            </GraphsWrapper>
          </MetricsContainer>
        </Grid>
      </Grid>
    </>
  );
};
