import { MenuItem, styled, Select, FormControl, Box, InputLabel, Divider } from '@mui/material';
import { CustomSelectProps } from './CostSection.interfaces';

export const StyledFormControl = styled(FormControl)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
});

export const StyledSelect = styled(Select, {
  shouldForwardProp: (propName) => propName !== 'placeholder',
})<CustomSelectProps>(({ theme, placeholder }) => ({
  '& .MuiSelect-select .notranslate::after': placeholder
    ? {
        content: `"${placeholder}"`,
        opacity: 0.42,
      }
    : {},
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
  '& .MuiInputLabel-root': {
    color: theme.palette.text.secondary,
    marginTop: '8px',
    marginBottom: '16px',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: theme.palette.surface.onSurfaceSubdued,
  },
  '& .MuiInputLabel-root.MuiFormLabel-filled': {
    color: theme.palette.surface.onSurfaceSubdued,
  },
  '& .MuiInputBase-input': {
    color: theme.palette.surface.onSurfaceSubdued,
  },
}));

export const OptionItem = styled(MenuItem)(({ theme }) => ({
  backgroundColor: theme.palette.surface.surfaceContainerLow,
  color: theme.palette.surface.onSurface,
  '&:hover': {
    backgroundColor: theme.palette.surface.surfaceContainerHigh,
    color: theme.palette.surface.onSurface,
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.surface.surfaceContainerHigh,
    color: theme.palette.surface.onSurface,
  },
}));

export const InputContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.surface.surfaceContainerLow,
  marginBottom: '24px',
  padding: '8px 12px',
  display: 'inline-block',
  borderRadius: '8px',
  minWidth: '168px',
  width: 'auto',
  '&.disabled': { opacity: 0.4 },
}));

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  color: theme.palette.surface.onSurface,
  marginTop: '6px',
  '&.MuiInputLabel-shrink': {
    transform: 'translate(14px, -6px) scale(0.75)',
    color: theme.palette.surface.onSurface,
  },
}));

export const OptionDivider = styled(Divider)(({ theme }) => ({
  backgroundColor: theme.palette.outline.subdued,
  height: '1px',
  width: '100%',
}));
